<template>
    <div>
        <template>
            <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 1200px; margin: 0 auto;">
                <el-tab-pane :label="item.typeName" :name="item.typeId" v-for="(item,index) in activeTitle"
                             :key="index">
                    <div class="titleTap" v-if="isShowTitleTap">
                        <h1>{{item.typeName.substring(0,2)}}<span>{{item.typeName.substring(2,4)}}</span></h1>
                        <div></div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            'activeTitle': {type: Array},
            'defaultActiveName': {type: String, default: ''}
            , isShowTitleTap: {type: Boolean, default: true}
        },
        data() {
            return {
                activeName: this.defaultActiveName
            }
        },
        watch: {
            defaultActiveName(val) {
                this.activeName = val
            }
        },
        methods: {
            handleClick(tab) {
                const typeId = tab.name;
                this.$emit('tabclick', {typeId: typeId})
            }
        }
    }
</script>

<style>
    .titleTap {
        text-align: left;
    }

    .titleTap > h1 {
        font-size: 28px;
        margin-top: 56px;
        color: #005bac;
    }

    .titleTap > h1 > span {
        color: #000000;
    }

    .titleTap > div {
        width: 80px;
        height: 4px;
        background-color: #005bac;
        margin: 16px 0 74px 0;
    }

    /* 	.titleTap>p{
            font-size: 14px;
            color: #b3b6b5;
            margin-bottom: 53px;
        } */
    .el-tabs__item.is-active {
        color: #005bac;
    }

    .el-tabs__item {
        font-size: 18px;
    }

    .el-tabs__active-bar {
        background-color: #005bac;
        height: 4px;
    }

    .el-tabs__item:hover {
        color: #005bac;
    }

    .el-tabs__nav-wrap::after {
        height: 1px;
    }

    .el-tabs__item {
        height: 62px;
        line-height: 62px;
    }

</style>

<template>
    <div>
        <location :locationArr="locationArr"/>
        <title-tap v-if="typeList.length>0" :defaultActiveName="typeId" :activeTitle="typeList" @tabclick="tabclick"/>
        <!-- 商会动态-->
        <div class="titleTap-box">
            <div class="care" @click="goShdtDetail(item.shdtId)" v-for="(item,index) in resData.records" :key="index">
                <img :src="item.titleImage">
                <div class="care-content">
                    <h5>{{item.shdtTitle}}</h5>
                    <p>{{item.subContent}}</p>
                </div>
                <div class="care-time">
                    <h3>{{item.year}}</h3>
                    <p>{{item.monthAndDay}}</p>
                </div>
            </div>
            <el-pagination v-if="resData.total>0" style="text-align: right;margin: 60px 0;"
                           background
                           prev-text="上一页"
                           next-text="下一页"
                           layout="prev, pager, next"
                           :current-page="page"
                           @current-change="currentChange"
                           :page-size=4
                           :total="resData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import location from '@/components/location.vue'
    import titleTap from '@/components/titleTap.vue'

    export default {
        name: 'shdtList',
        components: {
            titleTap,
            location
        },
        props: {},
        data() {
            return {
                typeId: '',
                typeList: [],
                resData: {},
                page: 1,
                show: true,
                locationArr: null
            }
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile"
			        });
			}
		},
        mounted() {
			// 在页面渲染完毕后 获取传递来的typeid值 
			// 执行 获取 面包屑导航数据 tab栏的数据  以及该栏内的数据
            let typeId = this.$route.query.typeId;
            if ((typeId != null && typeId != '' && typeof (typeId) != 'undefined')) {
                this.typeId = typeId
                this.initLocation()
                this.getList();
            }

            this.getShdtTypeList();
        },
        methods: {
			// 获取emit传的typeid值 重新进入这个页面
            tabclick(item) {
                const typeId = item.typeId
                if (typeId != this.typeId) {
                    this.typeId = typeId
                    const url = this.$route.path;
                    this.$router.push({path: url, query: {typeId: typeId}})
                }
            },
            // 领导关怀分页点击事件
            currentChange(page) {
                this.page = page
                this.getList()
            },
            // 跳转至详情页
            goShdtDetail(id) {
                this.$router.push({name: 'shdtDetail', query: {id: id}})
            },
			// 获取面包屑导航的数据
            initLocation() {
                const params = {};
                params.typeId = this.typeId;
                this.$http.getShdtTypeName(params).then(res => {
                    if (res.code == 200) {
                        const locationArr = [{"title": "商会动态", "url": "/shdt/list"}];
                        locationArr.push({"title": res.data, "url": "/shdt/list?typeId=" + params.typeId})
                        this.locationArr = locationArr;
                    }
                })
            },
			// 获取tab分页标题的数据
            getShdtTypeList() {
                this.$http.shdtTypeList().then(res => {
                    if (res.code == '200') {
                        this.typeList = res.data
                        if (res.data.length > 0 && this.typeId == '') {
                            this.typeId = res.data[0].typeId
                            this.initLocation()
                            this.getList();
                        }
                    }
                })
            },
			// 该tab页内的数据
            getList() {
                this.$http.shdtList({typeId: this.typeId, page: this.page}).then(res => {
                    if (res.code == 200) {
                        this.resData = res.data
                    }
                })
            },
			// 刷新页面的方法
            refreshData() {
                let typeId = this.$route.query.typeId;
                if ((typeId != null && typeId != '' && typeof (typeId) != 'undefined')) {
                    this.typeId = typeId
                }
                this.resData = {}
                this.page = 1;
                this.initLocation()
                this.getList();
            }
        },
        watch: {
            $route: 'refreshData'
        }
    }
</script>
<style type="text/css">
    @import '~@/assets/css/points.css';
</style>
